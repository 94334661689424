import { Auth, Hub } from 'aws-amplify';
import { createContext, useEffect, useMemo, useState } from 'react';
import { getEmailId } from 'utils';

import insightsConfig, { TuiConfigs, uiConfigs } from '../config/config';
import AuthService from './AuthService';
import RoleService from './RoleService';

interface RoleDetails {
  name?: string;
  email_id?: string;
  role?: string;
  factory_access?: string;
  status?: string;
  last_login?: string;
  session_id?: string;
  session_created_time?: string;
  session_expiry_time?: string;
  help_info?: { name: string; email_id: string }[];
}

export interface AuthDetails {
  isLoading: boolean;
  userSession: any;
  uiConfigs: TuiConfigs;
  roleDetails?: RoleDetails;
}

const initialState: AuthDetails = {
  isLoading: true,
  userSession: null,
  uiConfigs: uiConfigs
};

interface IAuthContext {
  authDetails: AuthDetails;
  updateUiConfigFnc: ({ payload }: { payload: any }) => void;
  refreshTokenIfNeeded: () => Promise<void>;
  refreshSessionIfNeeded: () => Promise<AuthDetails>;
}

interface IUpdateUiConfigFnc {
  payload: {
    data: Record<string, any>;
    event: string;
  };
}

const AuthContext = createContext<IAuthContext>({
  authDetails: initialState,
  // eslint-disable-next-line no-empty-pattern
  updateUiConfigFnc: ({}: IUpdateUiConfigFnc) => {
    // intentionally left empty
  },
  refreshTokenIfNeeded: () => Promise.resolve(),
  refreshSessionIfNeeded: () => Promise.resolve(initialState)
});

const getAddress = (address: any) => {
  if (!address || typeof address !== 'string') return;

  address = address.trim();

  if (address.startsWith('[') && address.endsWith(']')) {
    const content = address.slice(1, -1);
    return content
      .split(',')
      .map((item: string) => decodeURIComponent(item.trim().replace(/^"|"$/g, '')))
      .filter(Boolean);
  }

  return [decodeURIComponent(address)];
};

export function useAuth() {
  const authService = AuthService.getInstance();
  const [authDetails, setAuthDetails] = useState<AuthDetails>(initialState);
  const [isRefreshingToken, setIsRefreshingToken] = useState(false);
  const [isRefreshingRole, setIsRefreshingRole] = useState(false);

  const roleService = useMemo(() => RoleService.getInstance(), []);

  const getRoleDetails = async (
    session: any,
    isRefresh = false
  ): Promise<RoleDetails | undefined> => {
    try {
      const token = session.getIdToken();
      const email_id = getEmailId(token);
      return await roleService.fetchRole(
        email_id,
        `${token.payload.given_name} ${token.payload.family_name}`,
        getAddress(token.payload?.nickname) || [],
        token.payload.auth_time,
        isRefresh
      );
    } catch (error) {
      console.error('Error fetching role:', error);
      return undefined;
    } finally {
      if (!isRefresh) setIsRefreshingRole(false);
    }
  };

  const handleAuth = ({ payload }: { payload: any }) => {
    switch (payload.event) {
      case 'signIn':
      case 'cognitoHostedUI':
        setAuthDetails((prev) => ({
          ...prev,
          isLoading: true,
          userSession: payload.data.getSignInUserSession()
        }));
        break;
      case 'signInFailure':
      case 'tokenRefresh_failure':
      case 'signOut':
        setAuthDetails((prev) => ({
          ...prev,
          isLoading: false,
          userSession: null,
          roleDetails: undefined
        }));
        break;
      default:
        break;
    }
  };

  const updateUiConfigFnc = ({ payload }: { payload: any }) => {
    if (payload.event === 'updateUiConfig') {
      setAuthDetails((prev) => ({
        ...prev,
        uiConfigs: { ...prev.uiConfigs, ...payload.data }
      }));
    }
  };

  const initializeAuth = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      const userSession = userData.getSignInUserSession();
      const roleDetails = userSession ? await getRoleDetails(userSession) : undefined;

      setAuthDetails((prev) => ({
        ...prev,
        isLoading: false,
        userSession: userSession,
        roleDetails: roleDetails
      }));
    } catch (error) {
      console.log('Not signed in');
      setAuthDetails((prev) => ({
        ...prev,
        isLoading: false,
        userSession: null,
        roleDetails: undefined
      }));
    }
  };

  const refreshTokenIfNeeded = async () => {
    if (isRefreshingToken || isRefreshingRole || authDetails?.isLoading) return;
    try {
      setIsRefreshingToken(true);
      const session = await authService.refreshTokenIfNeeded();
      if (
        session &&
        (!authDetails.userSession || session?.getIdToken() !== authDetails?.userSession?.token)
      ) {
        const roleDetails = await getRoleDetails(session);
        setAuthDetails((prev) => ({
          ...prev,
          isLoading: false,
          userSession: session,
          roleDetails: roleDetails
        }));
      } else if (session) {
        setAuthDetails((prev) => ({
          ...prev,
          isLoading: false,
          userSession: session
        }));
      }
    } catch (error) {
      console.error('Error refreshing token: ', error);
    } finally {
      setIsRefreshingToken(false);
    }
  };

  const refreshSessionIfNeeded = async (): Promise<AuthDetails> => {
    try {
      if (authDetails.userSession && !authDetails.roleDetails) {
        const roleDetails = await getRoleDetails(authDetails.userSession);
        setAuthDetails((prev) => ({ ...prev, roleDetails }));
      } else if (authDetails?.roleDetails?.session_expiry_time) {
        const expiryTime = new Date(authDetails.roleDetails.session_expiry_time).getTime();
        const timeDiff = expiryTime - Date.now();

        if (timeDiff < 300000) {
          const roleDetails = await getRoleDetails(authDetails.userSession, true);
          const updatedAuthDetails = {
            ...authDetails,
            roleDetails: roleDetails
          };
          setAuthDetails(updatedAuthDetails);
          return updatedAuthDetails;
        }
      }
      return authDetails;
    } catch (error) {
      console.error('Error refreshing session: ', error);
      return authDetails;
    }
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      refreshTokenIfNeeded();
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshTokenIfNeeded();
      refreshSessionIfNeeded();
    }, insightsConfig.amplify.refreshTokenInterval * 60000);

    Hub.listen('auth', handleAuth);
    initializeAuth();
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(intervalId);
      Hub.remove('auth', handleAuth);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return {
    authDetails,
    handleAuth,
    updateUiConfigFnc,
    refreshTokenIfNeeded,
    isRefreshingToken,
    isRefreshingRole,
    refreshSessionIfNeeded
  };
}

export { AuthContext };
